import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Table, Dropdown, Row, Menu, Space, Input, Modal } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import * as xlsx from 'xlsx';

import { usePaginatedTableData, useTerminateProject, useDeleteProject, useGetInspectionCheckList } from './queries';
import usePaginatedQuries from '@hooks/usePaginatedQuries';
import { TableType, ProjectInfoType, ExcelData } from './types';
import {
  PROJECT_STATUS_ONGOING,
  PROJECT_STATUS_TERMINATION,
  PROJECT_STATUS_EXPECTED,
  PROJEC_STATUS_SUPENSION,
} from '@constants/project';
import { TableTotalCount } from '@components/common/styles';
import useModal from '@hooks/useModal';
import RestartModal from './Modal/RestartModal';
import UserLoginLogModal from './Modal/UserLoginLogModal';
import { formatBytes } from '@utils/size';
import EditManagerModal from './Modal/EditManagerModal';
import EditProjectInfoModal from './Modal/EditProjectInfoModal';
import PJModal from './Modal/PJModal';
import ConnectionModal from './Modal/ConnectionModal';

const { Search } = Input;
const { confirm } = Modal;

const ProjectTable = () => {
  const navigate = useNavigate();
  const { pathname, page, size, search } = usePaginatedQuries();
  const { visible, openModal, closeModal } = useModal();
  const { visible: logVisible, openModal: openLogModal, closeModal: closeLogModal } = useModal();
  const {
    visible: editManagerVisible,
    openModal: editManagerOpenModal,
    closeModal: editManagerCloseModal,
  } = useModal();
  const {
    visible: editProjectInfoVisible,
    openModal: editProjectInfoOpenModal,
    closeModal: editProjectInfoCloseModal,
  } = useModal();
  const { visible: pjVisible, openModal: pjOpenModal, closeModal: pjCloseModal } = useModal();
  const { visible: connectionVisible, openModal: connectionOpenModal, closeModal: connectionCloseModal } = useModal();
  const { menu } = useParams();
  const status = Number(menu) ?? 0;

  const { data: projects, isLoading } = usePaginatedTableData({
    status,
    page,
    size,
    search,
  });
  const { mutate: mutateTerminate } = useTerminateProject({ status, page, size, search });
  const { mutate: mutateDelete } = useDeleteProject({ status, page, size, search });
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [projectInfo, setProjectInfo] = useState<ProjectInfoType>(PROJECT_INFO_INITIAL_DATA);

  const [excel, setExcel] = useState({
    id: 0,
    name: '',
  });
  const { data: excelData } = useGetInspectionCheckList(excel.id);

  useEffect(() => {
    if (excelData) {
      downloadInspectionCheckList();
      setExcel({
        id: 0,
        name: '',
      });
    }
  }, [excelData, excel.id]);

  const downloadInspectionCheckList = () => {
    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(excelData as Array<ExcelData>, { header: INSPECTION_EXCEL_HEADERS });

    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(wb, `${excel.name}_검측 체크리스트_${dayjs(new Date()).format('YYYY.MM.DD')}.xlsx`);
  };

  const handleLoginLog = (id: number) => {
    setSelectedRow(id);
    openLogModal();
  };

  const handleResetProjectId = () => {
    setProjectInfo((prevData) => prevData && PROJECT_INFO_INITIAL_DATA);
  };

  const folderMenu = (record: TableType) => {
    return (
      <Menu>
        <Menu.Item key="history" onClick={() => handleLoginLog(record.id)}>
          로그인 기록
        </Menu.Item>
        {(status === PROJECT_STATUS_ONGOING ||
          status === PROJECT_STATUS_TERMINATION ||
          status === PROJEC_STATUS_SUPENSION) && (
          <Menu.Item
            key="change"
            onClick={() => {
              editManagerOpenModal();
              setProjectInfo((prevData) => ({
                ...prevData,
                projectId: record.id,
                created_user_id: record.created_user_id,
              }));
            }}
          >
            담당자 변경
          </Menu.Item>
        )}
        {status === PROJECT_STATUS_ONGOING && (
          <>
            <Menu.Item
              key="pj"
              onClick={() => {
                pjOpenModal();
                handleProjectInfo(record);
              }}
            >
              PJ오너
            </Menu.Item>
            <Menu.Item
              key="edit"
              onClick={() => {
                handleProjectInfo(record);
                editProjectInfoOpenModal();
              }}
            >
              수정
            </Menu.Item>
          </>
        )}
        {status !== PROJECT_STATUS_ONGOING && (
          <Menu.Item
            key="reactivation"
            onClick={() => {
              handleProjectInfo(record);
              openRestartModal(record.id);
            }}
          >
            재활성
          </Menu.Item>
        )}
        <Menu.Item key="checklist" onClick={() => showDownloadConfirm({ id: record.id, name: record.name })}>
          검측체크리스트
        </Menu.Item>
        <Menu.Item
          key="connection"
          onClick={() => {
            setProjectInfo((prev) => ({
              ...prev,
              projectId: record.id,
              link_by_category: record.link_by_category,
            }));
            connectionOpenModal();
          }}
        >
          유료연동
        </Menu.Item>
        {status !== PROJECT_STATUS_TERMINATION && status !== PROJECT_STATUS_EXPECTED && (
          <Menu.Item key="termination" onClick={() => showTerminationConfirm(record.id)}>
            종료
          </Menu.Item>
        )}
        {status === PROJECT_STATUS_TERMINATION && (
          <>
            <Menu.Item key="download">다운로드</Menu.Item>
            <Menu.Item key="delete" onClick={() => showDeleteConfirm(record.id)}>
              삭제
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  const handleProjectInfo = (record: TableType) => {
    setProjectInfo((prev) => ({
      ...prev,
      projectId: record.id,
      memberCount: record.total_member_count,
      storageSize: +record.total_size,
      endDate: record.end_date,
      company_names: record.company_names,
    }));
  };

  const openRestartModal = (id: number) => {
    setSelectedRow(id);
    openModal();
  };

  const showTerminationConfirm = (id: number) => {
    confirm({
      title: '프로젝트를 종료하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        mutateTerminate(id);
      },
    });
  };

  const showDeleteConfirm = (id: number) => {
    confirm({
      title: '프로젝트를 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        mutateDelete(id);
      },
    });
  };

  const showDownloadConfirm = ({ id, name }: { id: number; name: string }) => {
    confirm({
      title: '해당 프로젝트 검측 체크리스트를 다운로드 받으시겠습니까?',
      icon: <OkConfirmIcons />,
      onOk() {
        setExcel((prev) => ({
          ...prev,
          id,
          name,
        }));
      },
    });
  };

  const columns = [
    {
      title: '프로젝트명',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, { name, id }: { name: string; id: number }) => (
        <StyledProjectName onClick={() => navigate(`${pathname}/docs/${id}?projectName=${name}`)}>
          {name}
        </StyledProjectName>
      ),
    },
    {
      title: '유형',
      dataIndex: 'code_detail_name',
      key: 'code_detail_name',
    },
    {
      title: '종류',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '인원',
      dataIndex: 'total_member_count',
      key: 'total_member_count',
    },
    {
      title: '용량',
      dataIndex: 'size',
      key: 'size',
      render: (_: string, record: TableType) => (
        <div>{`${formatBytes(record.total_size)} 중 ${formatBytes(record.current_size)}`}</div>
      ),
    },
    {
      title: '생성일',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (text: Date) => text && dayjs(text).format('YY.MM.DD'),
    },
    {
      title: '종료일',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (text: Date) => text && dayjs(text).format('YY.MM.DD'),
    },
    {
      title: '담당자',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'PJ코드',
      dataIndex: 'psn',
      key: 'psn',
    },
    {
      title: 'PJ오너',
      dataIndex: 'company_names',
      key: 'company_names',
    },
    {
      title: '',
      dataIndex: 'setting',
      width: 20,
      render: (_: string, record: TableType) => (
        <Dropdown overlay={folderMenu(record)} trigger={['click']}>
          <Row justify="center">
            <EllipsisOutlined style={{ cursor: 'pointer' }}></EllipsisOutlined>
          </Row>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Row justify="space-between" align="middle">
          <Row>
            <Space size="middle">
              <TableTotalCount>{projects && `총 ${projects.count}개 프로젝트`}</TableTotalCount>
              {status === PROJECT_STATUS_EXPECTED && (
                <StyledContent>삭제예정탭에 옮겨진지 30일 뒤에 자동으로 삭제됩니다.</StyledContent>
              )}
            </Space>
          </Row>
          <Search
            onSearch={(value) => navigate(`${pathname}?page=${1}&size=${size}${value ? `&search=${value}` : ''}`)}
            allowClear
            placeholder="프로젝트명"
            style={{ width: '300px' }}
          />
        </Row>
        <Table
          columns={columns}
          dataSource={projects ? projects.list.map((item: TableType) => ({ ...item, key: item.id })) : []}
          loading={isLoading}
          bordered={true}
          pagination={{
            current: page,
            pageSize: size,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            total: projects?.count,
            onChange: (page, pageSize) =>
              navigate(`${pathname}?page=${page}&size=${pageSize}${search ? `&search=${search}` : ''}`),
          }}
        ></Table>
      </Space>

      {visible && (
        <RestartModal
          title="재활성"
          visible={visible}
          onCancel={closeModal}
          page={page}
          size={size}
          projectInfo={projectInfo}
        ></RestartModal>
      )}
      {logVisible && (
        <UserLoginLogModal
          title="로그인 기록"
          visible={logVisible}
          onCancel={closeLogModal}
          id={selectedRow}
        ></UserLoginLogModal>
      )}
      {editManagerVisible && (
        <EditManagerModal
          visible={editManagerVisible}
          onCancel={editManagerCloseModal}
          projectInfo={projectInfo}
          handleResetProjectId={handleResetProjectId}
          status={status}
        />
      )}
      {editProjectInfoVisible && (
        <EditProjectInfoModal
          visible={editProjectInfoVisible}
          projectInfo={projectInfo}
          page={page}
          size={size}
          search={search}
          onCancel={editProjectInfoCloseModal}
          handleResetProjectId={handleResetProjectId}
        />
      )}
      {pjVisible && (
        <PJModal
          visible={pjVisible}
          onCancel={pjCloseModal}
          projectInfo={projectInfo}
          page={page}
          size={size}
          search={search}
        />
      )}
      {connectionVisible && (
        <ConnectionModal
          visible={connectionVisible}
          onCancel={connectionCloseModal}
          projectInfo={projectInfo}
          status={status}
          page={page}
          size={size}
          search={search}
        />
      )}
    </>
  );
};

export default ProjectTable;

const INSPECTION_EXCEL_HEADERS = ['Id', '공사', '공종', '세부공종', '항목여부', '검사항목', '검사기준'];

const PROJECT_INFO_INITIAL_DATA = {
  projectId: 0,
};

const StyledContent = styled.div`
  font-size: 14px;
  color: #a0a0a0;
`;

const OkConfirmIcons = styled(CheckCircleOutlined)`
  color: #52c41a !important;
`;

const StyledProjectName = styled.span`
  cursor: pointer;
`;
